.genAi .chartBlock {
  border: 1px solid #3498db;
  /* Updated border color */
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  /* Added box shadow */
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 15px;
  min-height: 530px;
}
.chart-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
}
